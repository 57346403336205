import { createRouter, createWebHashHistory } from 'vue-router'



const routes = [
  {
    path: '/',
    name: 'listIndex',
    meta:{
      next:'项目展示'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/list/index')
  },
  {
    path: '/listImg',
    name: 'listImg',
    meta:{
      next:'二维码'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/list/img')
  },
  {
    path: '/location',
    name: 'LocationView',
    meta:{
      next:'二维码'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/location')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
