<template>
  <router-view/>
</template>

<style lang="scss">
// #__vconsole {
   
//    display: none; 
//    &.show{ 
//     display: block; 
//    } 
//    }
</style>
